import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Loader from '../../../components/loader';
import Seo from '../../../components/seo';
import PersistError from '../../../components/persistError';

const Admin = ({ password, id }: any) => {
  const [stage, setStage] = useState('loading');
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`/api/firebase/${password}/${id}`);
        console.log('response', response);
        setData(response.data.participants);
        setStage('success');
      } catch (error) {
        setStage('error');
      }
    })();
  }, []);

  return (
    <div className="container mx-auto py-6 px-4">
      <Seo title="Übersicht" description="Übersicht" />
      {stage === 'loading' && <Loader text="Kleinen Augenblick..." />}
      {stage === 'error' && <PersistError />}
      {stage === 'success' && (
        <>
          <h2>Übersicht Anmeldungen</h2>
          <table className="w-full">
            <thead>
              <tr>
                <th className="text-left"></th>
                <th className="text-left">Nachname</th>
                <th className="text-left">Vorname</th>
                <th className="text-left">Straße</th>
                <th className="text-left">Email</th>
                <th className="text-left">Abgemeldet</th>
              </tr>
            </thead>
            <tbody>
              {data.map((child: any, index: number) => (
                <tr
                  key={child.firstname + child.lastname}
                  className={index % 2 === 0 ? 'bg-neutral-200' : ''}
                >
                  <td>{index + 1}</td>
                  <td>{child.lastname}</td>
                  <td>{child.firstname}</td>
                  <td>{child.street}</td>
                  <td>{child.email}</td>
                  <td>{child.remove && 'Abgemeldet'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default Admin;
